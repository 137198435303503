#outerdiv {
	width: 100%;
	height: 220px;
	min-height: 100px;
	overflow: hidden;
	border-style: solid;
	position: absolute;
	right: 0;
	bottom: 0;
	left: 0;
	background: red;
}

#inneriframe {
	position: absolute;
	top: --0px;
	left: -0px;
	width: 100%;
	height: 100%;
}
