body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 100%;
  overflow-x: hidden;
  max-height: 100%;
  overscroll-behavior: none;

  &.modal-open {
    overflow-y: hidden;

    &::after {
      background-color: rgba(0, 0, 0, 0.5);
      z-index: 999;
      z-index: 999;
    }
  }
}

*:focus {
  outline: none;
}

code {
  font-family: "HerosRegular";
}

a {
  text-decoration: none;
}

p {
  margin: 0;
}

button {
  padding: 0 !important;
}

h1,
h2,
h3,
h4 {
  margin: 0;
}
