@use "../../global.scss";

.arrivalsContainer {
	background-color: global.$black;
	color: global.$white;
	padding: 100px 90px;

	@media screen and (max-width: global.$extraLarge) {
		padding: 100px 50px;
	}

	@media screen and (max-width: global.$large) {
		padding: 85px 20px;
	}

	@media screen and (max-width: global.$small) {
		padding: 55px 15px;
	}
}

.arrivalsTitle {
	font-size: 34px;
	font-weight: 300;
	letter-spacing: 2px;
	color: global.$white;

	text-align: center;
	margin-bottom: 80px;

	@media screen and (max-width: global.$extraLarge) {
		font-size: 30px;
		margin-bottom: 50px;
	}

	@media screen and (max-width: global.$medium) {
		margin-bottom: 40px;
	}

	@media screen and (max-width: global.$small) {
		font-size: 20px;
	}
}

.arrivalsSubtitle {
	font-size: 16px;
	line-height: 26px;
	font-weight: 300;
	letter-spacing: 1px;
	color: global.$white;
	text-align: center;

	@media screen and (max-width: global.$small) {
		font-size: 14px;
		line-height: 20px;
	}

	&.space {
		margin-bottom: 80px;

		@media screen and (max-width: global.$extraLarge) {
			margin-bottom: 50px;
		}

		@media screen and (max-width: global.$medium) {
			margin-bottom: 40px;
		}
	}
}

.arrivalsBtnWrapper {
	display: flex;
	justify-content: center;
	margin-top: 80px;

	@media screen and (max-width: global.$extraLarge) {
		margin-top: 50px;
	}

	@media screen and (max-width: global.$medium) {
		margin-top: 40px;
	}
}
